import type { ContentType } from '~/types'
import { PID } from '~/types'

interface ConfigState {
  leaderboardPID?: PID
  contentId?: string
  contentType?: ContentType
  showAds: boolean
  mediaSrc?: string
}

export const useConfigStore = defineStore('config', {
  state: (): ConfigState => ({
    leaderboardPID: PID.leaderboard_homepage,
    contentId: undefined,
    contentType: undefined,
    showAds: true,
    mediaSrc: undefined,
  }),
  actions: {
    setState({ leaderboardPID, contentId, contentType, showAds, mediaSrc }: ConfigState) {
      this.leaderboardPID = leaderboardPID
      this.contentId = contentId
      this.contentType = contentType
      this.showAds = showAds
      this.mediaSrc = mediaSrc
    },
    resetState() {
      this.leaderboardPID = undefined
      this.contentId = undefined
      this.contentType = undefined
      this.mediaSrc = undefined
      // Do not reset this.showAds, this is set by specific routes and should persist
    },
  },
})
