/**
 * @link https://github.com/bittorrent/ads-config-server/blob/master/config/prod/rainberrytv.json
 */
export enum PID {
  leaderboard_homepage = 'leaderboard_homepage',
  leaderboard_categories = 'leaderboard_categories',
  leaderboard_watch = 'leaderboard_watch',
  mrec_homepage = 'mrec_homepage',
  mrec_categories = 'mrec_categories',
  mrec_watch = 'mrec_watch',
  video_player_watch = 'video_player_watch',
}

export interface Content {
  fetchId: string
  id: string
  title?: string
  description?: string
  thumbnail?: string
  duration?: string
  type?: ContentType
  mediaSrc?: string
}

export type ContentType =
  'animation'
  | 'anime'
  | 'comic-heroes'
  | 'documentary'
  | 'fantasy'
  | 'horror'
  | 'musical'
  | 'reality-tv'
  | 'sci-fi'
  | 'new-releases-games'
  | 'new-releases-movies'
  | 'new-releases-shows'

export interface ContentQueryItem {
  label: string
  href: string
  type: ContentType
}

export interface PageResult<T> {
  total: number
  results: T[]
}

export interface Placement {
  pid: string
  adurl: string
  geo: string
}

export interface Targeting {
  rollout: string
  country: string
  source_ip: string
}
